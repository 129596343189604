import React, { Component } from 'react';
import { compose } from 'recompose';
import { 
  AuthUserContext, withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
// const HomePage = () => (
//   <div>
//     <h1>Home Page</h1>
//     <p>The Home Page is accessible by every signed in user.</p>
//     <Messages />
//   </div>
// );
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
  }; }
  componentDidMount() {
    this.props.firebase.users().on('value', snapshot => {
      this.setState({
        users: snapshot.val(),
      }); 
    });
  }
  componentWillUnmount() {
    this.props.firebase.users().off();
  }
  render() {
    return (<div>
      <h1>Home Page</h1>
      <p>The Home Page is accessible by every signed in user.</p>
      <Messages users={this.state.users} />
    </div>)
  }
}
class MessagesBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      loading: false,
      messages: [],
      limit: 5,
    }; 
  }
  onChangeText = event => {
    this.setState({ text: event.target.value });
  };
  onCreateMessage = (event, authUser) => {
    this.props.firebase.messages().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });
    this.setState({ text: '' });
    event.preventDefault();
  };
  onEditMessage = (message, text) => {
        this.props.firebase.message(message.uid).set({
        ...message,
        text,
        editedAt: this.props.firebase.serverValue.TIMESTAMP,
      });
    }
  onRemoveMessage = (uid) => {
    this.props.firebase.message(uid).remove();
  };
  componentDidMount() {
    this.onListenForMessages();
  }
  onListenForMessages() {
    this.setState({ loading: true });
    this.props.firebase.messages()
        .orderByChild('createdAt')
        .limitToLast(this.state.limit)
        .on('value', snapshot => {
      // convert messages list from snapshot
      const messageObject = snapshot.val();
      if (messageObject) {
        const messageList = Object.keys(messageObject).map(key => ({
          ...messageObject[key],
          uid: key,
        }));

        this.setState({ messages: messageList,loading: false });
      } else {
        this.setState({ messages: null, loading: false });
      }
      
    });
  }
  componentWillUnmount() {
    this.props.firebase.messages().off();
  }
  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForMessages,
    );
  };
  render() {
    const { text, messages, loading } = this.state;
    const { users } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => 
        (<div>
          {!loading && messages && (
              <button type="button" onClick={this.onNextPage}>
                More
              </button>
          )}
        {loading && <div>Loading ...</div>}
        {messages ? (
        <MessageList 
          // messages={messages} 
          messages={messages.map(message => ({
            ...message,
            user: users
              ? users[message.userId]
              : { userId: message.userId },
          }))}
          onEditMessage={this.onEditMessage}
          onRemoveMessage={this.onRemoveMessage}
        />
        ):(
        <div>There are no messages ...</div>
        )}
        <form onSubmit={e => this.onCreateMessage(e,authUser)}>
          <input
            type="text"
            value={text}
            onChange={this.onChangeText}
          />
          <button type="submit">Send</button>
        </form>
        </div>)}
      </AuthUserContext.Consumer>
      
    ); 
  }
}

const MessageList = ({ messages, onEditMessage, onRemoveMessage }) => (
  <ul>
    {messages.map(message => (
      <MessageItem key={message.uid} 
      message={message} 
      onEditMessage={onEditMessage} 
      onRemoveMessage={onRemoveMessage}/>
    ))} 
  </ul>
);
class MessageItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      editText: this.props.message.text,
    }
  }
  onToggleEditMode = () => {
      this.setState(state => ({
        editMode: !state.editMode,
        editText: this.props.message.text,
    }));
  };
  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };
  onSaveEditText = () => {
    this.props.onEditMessage(this.props.message, this.state.editText);
    this.setState({ editMode: false });
  };
  render() {
    const { message, onRemoveMessage } = this.props;
    const { editMode, editText } = this.state;
    return (
      <li>
        {editMode ? (
          <input
            type="text"
            value={editText}
            onChange={this.onChangeEditText}
          /> ):(
          <span>
            {/* <strong> {message.user.username || message.user.userId} */}
            <strong> {message.userId}
            </strong> {message.text}
            {message.editedAt && <span>(Edited)</span>}
          </span>
          )}
        {editMode ? (
          <span>
            <button onClick={this.onSaveEditText}>Save</button>
            <button onClick={this.onToggleEditMode}>Reset</button>
          </span>
        ):(
        <button onClick={this.onToggleEditMode}>Edit</button>
        )}
        {!editMode && (
          <button
            type="button"
            onClick={() => onRemoveMessage(message.uid)}
          > Delete
          </button>
          )}

    </li>
    )
  }
}

const condition = authUser => !!authUser;
const Messages = withFirebase(MessagesBase);
export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);